import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="dragon-disciple"></a><h3>DRAGON DISCIPLE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG184_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d12.</p>
    <a id="dragon-disciple-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a dragon disciple, a
character must fulfill all the following criteria.</p>
    <p><b>Race</b>: Any nondragon (cannot already be a half-dragon).</p>
    <p><b>Skills</b>: Knowledge (arcana) 8 ranks.</p>
    <p><b>Languages</b>: Draconic.</p>
    <p><b>Spellcasting</b>: Ability to cast arcane spells without
preparation.</p>
    <p><b>Special</b>: The player chooses a dragon variety when
taking the first level in this prestige class.</p>
    <h6>Class Skills</h6>
    <p className="initial">The dragon disciple&#8217;s class skills (and the
key ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a>
(Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#escape-artist">Escape
Artist</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#gather-information">Gather Information</a> (Cha),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills,
taken individually) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#speak-language">Speak Language</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
(Int), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a> (Wis). </p>
    <p><b>Skill Points at Each Level</b>: 2 + Int modifier.</p>
    <a id="table-the-dragon-disciple"></a><p><b>Table: The Dragon Disciple</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "35%"
          }}>Special</th>
          <th style={{
            "width": "15%"
          }}>Bonus Spells</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>+2</td>
          <td>Natural armor increase (+1)</td>
          <td>1</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+0</td>
          <td>+3</td>
          <td>Ability boost (Str +2), claws and bite</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+1</td>
          <td>+3</td>
          <td>Breath weapon (2d8)</td>
          <td>0</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>Ability boost (Str +2), natural armor increase (+2)</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>Blindsense 30 ft.</td>
          <td>1</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>Ability boost (Con +2)</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>Breath weapon (4d8), natural armor increase (+3)</td>
          <td>0</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>Ability boost (Int +2)</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>Wings</td>
          <td>1</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+7</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">Blindsense 60 ft., dragon apotheosis</td>
          <td className="last-row">0</td>
        </tr>
      </tbody>
    </table>
    <a id="dragon-disciple-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are Class Features of the
dragon disciple prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Dragon disciples gain no
proficiency with any weapon or armor.</p>
    <p><b>Bonus Spells</b>: Dragon disciples gain bonus spells as
they gain levels in this prestige class, as if from having a high
ability score, as given on Table: The Dragon Disciple. A bonus spell
can be added to any level of spells the disciple already has the
ability to cast.</p>
    <p>If a character has more than one spellcasting class, he must
decide to which class he adds each bonus spell as it is gained. Once a
bonus spell has been applied, it cannot be shifted.</p>
    <a id="dragon-disciple-natural-armor-increase"></a><p><b>Natural Armor Increase (Ex)</b>: At 1st, 4th, and 7th
level, a dragon disciple gains an increase to the character&#8217;s existing natural armor
(if any), as indicated on Table: The Dragon Disciple (the numbers
represent the total increase gained to that point). As his skin
thickens, a dragon disciple takes on more and more of his progenitor&#8217;s
physical aspect.</p>
    <a id="dragon-disciple-claws-and-bite"></a><p><b>Claws and Bite (Ex)</b>: At 2nd level, a dragon disciple
gains claw and bite attacks if he does not already have them. Use the
values below or the disciple&#8217;s base claw and bite damage values,
whichever are greater.</p>
    <a id="table-dragon-disciple-bite-and-claw-damage"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>Size</th>
          <th style={{
            "width": "110px"
          }}>Bite Damage</th>
          <th style={{
            "width": "110px"
          }}>Claw Damage</th>
        </tr>
        <tr className="odd-row">
          <td>Small</td>
          <td>1d4</td>
          <td>1d3</td>
        </tr>
        <tr>
          <td>Medium</td>
          <td>1d6</td>
          <td>1d4</td>
        </tr>
        <tr className="odd-row">
          <td>Large</td>
          <td>1d8</td>
          <td>1d6</td>
        </tr>
      </tbody>
    </table>
    <p>A dragon disciple is considered proficient with these attacks.
When making a full attack, a dragon disciple uses his full base attack
bonus with his bite attack but takes a &#8211;5 penalty on claw attacks. The
Multiattack feat reduces this penalty to only &#8211;2.</p>
    <a id="dragon-disciple-ability-boost"></a><p><b>Ability Boost (Ex)</b>: As a dragon disciple gains levels
in this prestige class, his ability scores increase as noted on Table:
The Dragon Disciple.</p>
    <p>These increases stack and are gained as if through level
advancement.</p>
    <a id="dragon-disciple-breath-weapon"></a><p><b>Breath Weapon (Su)</b>: At 3rd level, a dragon disciple
gains a minor breath weapon. The type and shape depend on the dragon
variety whose heritage he enjoys (see below). Regardless of the
ancestor, the breath weapon deals 2d8 points of damage of the
appropriate energy type.</p>
    <p>At 7th level, the damage increases to 4d8, and when a disciple
attains dragon apotheosis at 10th level it reaches its full power at
6d8. Regardless of its strength, the breath weapon can be used only
once per day. Use all the rules for dragon breath weapons except as
specified here.</p>
    <p>The DC of the breath weapon is 10 + class level + Con modifier.</p>
    <p>A line-shaped breath weapon is 5 feet high, 5 feet wide, and
60 feet long. A cone-shaped breath weapon is 30 feet long.</p>
    <a id="table-dragon-disciple-breath-weapon"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "300px"
    }}>
      <tbody>
        <tr>
          <th>Dragon Variety<sup>*</sup></th>
          <th>Breath Weapon</th>
        </tr>
        <tr className="odd-row">
          <td>Black</td>
          <td>Line of acid</td>
        </tr>
        <tr>
          <td>Blue</td>
          <td>Line of lightning</td>
        </tr>
        <tr className="odd-row">
          <td>Green</td>
          <td>Cone of corrosive gas (acid)</td>
        </tr>
        <tr>
          <td>Red</td>
          <td>Cone of fire</td>
        </tr>
        <tr className="odd-row">
          <td>White</td>
          <td>Cone of cold</td>
        </tr>
        <tr>
          <td>Brass</td>
          <td>Line of fire</td>
        </tr>
        <tr className="odd-row">
          <td>Bronze</td>
          <td>Line of lightning</td>
        </tr>
        <tr>
          <td>Copper</td>
          <td>Line of acid</td>
        </tr>
        <tr className="odd-row">
          <td>Gold</td>
          <td>Cone of fire</td>
        </tr>
        <tr>
          <td className="last-row">Silver</td>
          <td className="last-row">Cone of cold</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Other varieties of dragon disciple
are possible, using other dragon varieties as ancestors.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="dragon-disciple-blindsense"></a><p><b>Blindsense (Ex)</b>: At 5th level, the dragon disciple
gains blindsense with a range of 30 feet. Using nonvisual senses the
dragon disciple notices things it cannot see. He usually does not need
to make Spot or Listen checks to notice and pinpoint the location of
creatures within range of his blindsense ability, provided that he has
line of effect to that creature.</p>
    <p>Any opponent the dragon disciple cannot see still has total
concealment against him, and the dragon disciple still has the normal
miss chance when attacking foes that have concealment. Visibility still
affects the movement of a creature with blindsense. A creature with
blindsense is still denied its Dexterity bonus to Armor Class against
attacks from creatures it cannot see. At 10th level, the range of this
ability increases to 60 feet.</p>
    <a id="dragon-disciple-wings"></a><p><b>Wings (Ex)</b>: At 9th level, a dragon disciple grows a set
of draconic wings. He may now fly at a speed equal to his normal land
speed, with average maneuverability.</p>
    <a id="dragon-disciple-dragon-apotheosis"></a><p><b>Dragon Apotheosis</b>: At 10th level, a dragon disciple
takes on the half-dragon template. His breath weapon reaches full
strength (as noted above), and he gains +4 to Strength and +2 to
Charisma. His natural armor bonus increases to +4, and he acquires
low-light vision, 60-foot darkvision, immunity to sleep and paralysis
effects, and immunity to the energy type used by his breath weapon (see
above).</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      